import Maps from 'components/common/Map';
import { Mobile, PC } from 'components/common/Responsive';

const Section2 = () => (
  <>
    <PC>
      <Maps height={500} />
    </PC>
    <Mobile>
      <Maps height={600} />
    </Mobile>
  </>
);
export default Section2;
