/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/home/list1.png';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useIsMobile } from 'core/hooks/useResponsive';

const Tip = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const Section5Style = css`
    width: 100%;
    text-align: center;
    padding-bottom: 200px;
    .container {
      width: 1360px;
      margin: 0 auto;
      > .title {
        font-size: 56px;
        font-weight: 700;
        line-height: 76px;
        text-align: center;
        span {
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        text-align: left;
        margin-top: 48px;
        padding: 0 80px;
        .swiper-button-prev,
        .swiper-button-next {
          width: 48px;
          height: 48px;
          background: #fff;
          border: 1px solid #e1e1e1;
          &::after {
            color: #98a2b3;
            font-size: 17px;
          }
        }
        li {
          cursor: pointer;
          flex-grow: 1;
          width: calc(33% - 24px);
          a {
            color: #000;
          }
          .imgBox {
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .text {
            padding-top: 8px;
            .title {
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 36px;
            }
            .desc {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: #878787;
              margin-top: 10px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }
      .more {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
        padding: 12px 20px;
        color: #6079ff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        gap: 8px;
        cursor: pointer;
      }
    }
    ${mq.mobile} {
      padding-bottom: 96px;
      .container {
        width: 100%;
        .title {
          font-size: 30px;
          line-height: 42px;
        }
        .list {
          padding: 0;
          margin-top: 32px;
          li {
            text-align: left;
            cursor: pointer;
            .text {
              padding-top: 8px;
              .title {
                font-size: 18px;
                line-height: 27px;
              }
              .desc {
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
        .more {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-top: 32px;
        }
      }
    }
  `;
  return (
    <div css={Section5Style}>
      <div className="container">
        <h2 className="title">
          몰파이와{isMobile && <br />} 새로운 변화를 만든
          <br />
          <span>기업 스토리</span>
        </h2>
        <div className="slide">
          <Swiper
            tag="ul"
            modules={[Navigation]}
            className="list"
            slidesPerView={isMobile ? 1.2 : 3}
            spaceBetween={isMobile ? 14 : 24}
            slidesOffsetBefore={isMobile ? 20 : 0}
            slidesOffsetAfter={isMobile ? 20 : 0}
            navigation={!isMobile}
          >
            <SwiperSlide tag="li">
              <a href="https://blog.mallpie.kr/upluskok/">
                <div className="imgBox">
                  <img
                    src="https://blog.mallpie.kr//content/images/size/w600/2024/06/kok.png"
                    alt="통신사가 커머스를? - 유플러스콕 | 이미지"
                    title="통신사가 커머스를? - 유플러스콕"
                  />
                </div>
                <div className="text">
                  <h3 className="title">통신사가 커머스를? - 유플러스콕</h3>
                  <p className="desc">
                    이커머스는 이미 우리 생활에 아주 밀접하게 맞닿아 있습니다.
                    우리가 자주 사용하는 다양한 쇼핑몰을 비롯해서 알게 모르게
                    온라인에서 상품을 마주치게 되고 지갑을 여는 일이 많죠. 국내
                    대표 통신사인 LG유플러스 또한 고객에게 혜택을 주는
                    수단으로써 커머스를 활용하고 있습니다. LG유플러스는
                    2020년부터 지니웍스의 커머스 플랫폼과 상품을 활용하여 자사
                    고객들에게 문자 메시지를 통해 어디서도 만나볼
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide tag="li">
              <a href="https://blog.mallpie.kr/adpopcorn/">
                <div className="imgBox">
                  <img
                    src="https://blog.mallpie.kr//content/images/size/w600/2024/06/Frame-1707480917.png"
                    alt="오퍼월에 다양한 상품을 - 애드팝콘 쇼핑적립 | 이미지"
                    title="오퍼월에 다양한 상품을 - 애드팝콘 쇼핑적립"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    오퍼월에 다양한 상품을 - 애드팝콘 쇼핑적립
                  </h3>
                  <p className="desc">
                    애드팝콘은 국내 최대 규모의 데이터 기반 모바일 마케팅
                    플랫폼으로써, 광고주에게는 디스플레이, 동영상, 보상형 광고의
                    기회를, 퍼블리셔에게는 다양한 광고 지면을 활용해 유저의 앱
                    충성도 신규 수익모델을 창출할 수 있는 기회를 제공하고 있는
                    AD Network입니다. [애드팝콘 이미지] 몰파이 비즈니스는
                    애드팝콘의 광고 플랫폼 중 무료 충전소 형태의 오퍼월 광고
                    지면에
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide tag="li">
              <a href="https://blog.mallpie.kr/hanapay/">
                <div className="imgBox">
                  <img
                    src="https://blog.mallpie.kr//content/images/size/w600/2024/06/hana-1.png"
                    alt="특가 상품으로 고객을 잡는다 - 하나Pay오늘특가 | 이미지"
                    title="특가 상품으로 고객을 잡는다 - 하나Pay오늘특가"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    특가 상품으로 고객을 잡는다 - 하나Pay오늘특가
                  </h3>
                  <p className="desc">
                    하나카드를 사용하는 고객이라면 누구나 하나Pay 앱을 통해 카드
                    사용에 관련된 정보 뿐 아니라 다양한 혜택을 누리기 위해
                    접속하고 있습니다. 이를 위해 하나Pay 앱에서는 결제금액에
                    따라 행운 복권 뽑기나 최저가 기프티콘(e-쿠폰) 등 고객의 사용
                    행태에 따라 여러가지 혜택을 제공하는 서비스를 제공하고
                    있는데요. [하나카드 APP 캡쳐] 많은 금융사들이 쇼핑 서비스를
                    고객에게
                  </p>
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Tip;
