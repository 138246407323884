/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import top1Img from 'static/home/top1.png';
import top2Img from 'static/home/top2.png';
import top3Img from 'static/home/top3.png';
import top4Img from 'static/home/top4.png';
import top5Img from 'static/home/top5.png';
import top6Img from 'static/home/top6.png';
import top7Img from 'static/home/top7.png';
import top8Img from 'static/home/top8.png';
import { useIsPc } from '../../../core/hooks/useResponsive';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';

const Top = () => {
  const { mq } = useTheme();
  const isPc = useIsPc();
  const topImgArr = [
    {
      img: top1Img,
      size: '279px',
      mSize: '200px',
    },
    {
      img: top2Img,
      size: '279px',
      mSize: '200px',
    },
    {
      img: top3Img,
      size: '359px',
      mSize: '200px',
    },
    {
      img: top4Img,
      size: '279px',
      mSize: '200px',
    },
    {
      img: top5Img,
      size: '254px',
      mSize: '177px',
    },
    {
      img: top6Img,
      size: '255px',
      mSize: '200px',
    },
    {
      img: top7Img,
      size: '254px',
      mSize: '177px',
    },
    {
      img: top8Img,
      size: '599px',
      mSize: '449px',
    },
  ];

  const TopStyle = css`
    max-width: 100%;
    width: 100%;
    height: 1472px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
      180deg,
      #f2f4ff 46.5%,
      rgba(242, 244, 255, 0) 100%
    );
    padding-bottom: 50px;
    padding-top: 220px;
    .title {
      font-size: 80px;
      text-align: center;
      font-weight: 800;
      line-height: 130%;
      span {
        font-size: inherit;
        font-weight: inherit;
        color: #6079ff;
      }
    }
    button {
      margin-top: 50px;
      width: 333px;
      padding: 8px 8px 8px 45px;
      display: flex;
      margin: 51px auto 0px auto;
      font-size: 25px;
      border-radius: 999px;
      border: 0;
      background: #000;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .arrow {
        width: 57px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #6079ff;
        border-radius: 50%;

        .img {
          position: relative;
          animation: moveArrow 0.6s ease-in-out infinite alternate;
        }
      }
      @keyframes moveArrow {
        0% {
          transform: translateX(-3px);
        }
        100% {
          transform: translateX(3px);
        }
      }
    }
    @keyframes dvufTn {
      0% {
        transform: translate(0px);
      }

      100% {
        transform: translate(-57%);
      }
    }
    .imgs {
      width: max-content;
      gap: 24px;
      align-items: flex-end;
      animation: 30s linear 0s infinite normal none running dvufTn;
      display: flex;
      margin-top: 128px;
      position: absolute;
      bottom: 160px;
      left: 0;
      li {
        img {
          width: 100%;
        }
      }
    }
    ${mq.mobile} {
      padding-top: 96px;
      padding-bottom: 50px;
      height: auto;
      .title {
        font-size: 30px;
        font-weight: 800;
        line-height: 45px;
      }
      .imgs {
        margin-top: 65px;
        position: static;
      }
      button {
        margin-top: 32px;
        width: 195px;
        padding: 8px 10px 8px 20px;
        font-size: 16px;
        .arrow {
          width: 30px;
          height: 30px;
          .img {
            width: 20px;
            height: 20px;
            position: relative;
            animation: moveArrow 0.6s ease-in-out infinite alternate;
          }
        }
        @keyframes moveArrow {
          0% {
            transform: translateX(-3px);
          }
          100% {
            transform: translateX(3px);
          }
        }
      }
    }
  `;

  return (
    <div css={TopStyle}>
      <h2 className="title">
        <span>상품 없이</span> 쇼핑몰을 만들고
        <br />
        <span>수익</span>을 올리세요
      </h2>
      <a href="https://my.mallpie.kr" target="_blank" rel="noopener noreferrer">
        <button>
          5분만에 몰 만들기
          <div className="arrow">
            <ArrowRight className="img" />
          </div>
        </button>
      </a>
      <ul className="imgs">
        {topImgArr.map((v, k) => (
          <li style={{ width: isPc ? v.size : v.mSize }} key={`${k}-1`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
        {topImgArr.map((v, k) => (
          <li style={{ width: isPc ? v.size : v.mSize }} key={`${k}-2`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Top;
