/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';

const Inquiry = () => {
  const { mq } = useTheme();
  const inquiryStyle = css`
    padding: 100px 0px;
    background: #f9fafb;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .text {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
    }
    button {
      margin-top: 20px;
      padding: 12px 20px;
      background: #6079ff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      cursor: pointer;
    }
    ${mq.mobile} {
      padding: 64px 0px;
      .text {
        font-size: 22px;
      }
      button {
        padding: 9px 20px;
      }
    }
  `;
  return (
    <div css={inquiryStyle}>
      <div className="text">원하는 답변을 찾지 못하셨나요?</div>
      <button
        onClick={() => window.open('https://my.mallpie.kr/customer/inquiry')}
      >
        1:1 문의하기
      </button>
    </div>
  );
};

export default Inquiry;
