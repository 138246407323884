/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import icon1 from 'static/inquiry/section1_1.png';
import icon2 from 'static/inquiry/section1_2.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    padding: 90px 0 120px 0;
    .container {
      width: 1200px;
      margin: 0 auto;
      .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
      }
      .sub {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .info {
        margin-top: 64px;
        padding-top: 64px;
        border-top: 2px solid #6079ff;
        display: flex;
        li {
          width: 50%;
          h4 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #6079ff;
          }
          h3 {
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
          }
          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            img {
              width: 24px;
              vertical-align: middle;
              margin-right: 12px;
            }
          }
          p + p {
            margin-top: 12px;
          }
        }
      }
    }

    ${mq.mobile} {
      .container {
        width: 100%;
        padding: 0 32px;
        .title {
          font-size: 36px;
          font-weight: 600;
          line-height: 54px;
        }
        .sub {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        .info {
          margin-top: 50px;
          padding-top: 50px;
          flex-direction: column;
          li {
            width: 100%;
            h4 {
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
              color: #6079ff;
            }
            h3 {
              font-size: 18px;
              line-height: 27px;
            }
            &:nth-of-type(2) {
              margin-top: 46px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section1Style}>
      <div className="container">
        <div className="title">문의</div>
        <div className="sub">
          플랫폼 도입 또는 제휴 등<br />
          궁금하신 점을 문의해주시면 확인 후 연락드리겠습니다.
        </div>
        <ul className="info">
          <li>
            <h4>지니웍스 오시는 길</h4>
            <h3>서울시 강남구 논현로 653 2층</h3>
          </li>
          <li>
            <p>
              <img src={icon1} alt="icon1" />
              서울시 강남구 논현로 653 2층
            </p>
            <p>
              <img src={icon2} alt="icon2" />
              sellerd@genieworks.net
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
