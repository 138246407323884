import React from 'react';

const useFaq = () => {
  const typeList = {
    mymall: '마이몰',
    seller: '입점사',
  };

  const categoryList = {
    mymall: {
      service: [
        {
          title: '몰파이는 어떤 서비스인가요?',
          content:
            "몰파이'는 상품이 없이도 쉽고 빠르게 나만의 쇼핑몰을 무료로 만들 수 있는 커머스 플랫폼입니다.등록되어있는 다양한 상품으로 내 쇼핑몰을 구성하고, 상품이 판매되면 수익금이 적립됩니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr'>📘이용가이드</a>를 참고해 주세요.",
          id: 1,
        },
        {
          title: '회원이 되기 위한 조건이 있나요?',
          content:
            "조건없이 누구나 가능합니다.<br/><br/>단, 사업자를 보유하고 있으나 간이 과세자인 경우 세금계산서 발행이 불가하기 때문에 개인/간이 과세자 (세금계산서 미발급)로 선택하시어 가입해주시면 됩니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/join'>📘회원가입 가이드</a>를 참고해 주세요.",
          id: 2,
        },
        {
          title: '가입 비용이 따로 있나요?',
          content:
            '무료로 가입을 하실 수 있습니다.<br/>판매 수익은 적립되며 현금으로 입금해 드립니다.',
          id: 3,
        },
      ],
      join: [
        {
          title: '상품이 없어도 마이몰을 만들 수 있나요?',
          content:
            '네, 몰파이에서는 가능 합니다. 몰파이에 등록되어있는 수 많은 상품을 자유롭게 선택하여 판매할 수 있어요.',
          id: 1,
        },
        {
          title: '사업자가 아니여도 가입 가능한가요?',
          content:
            '네, 개인, 사업자 모두 가능합니다.<br/><br/>단, 사업자 가입 시 간이 과세자는 세금계산서 발행이 불가하기 때문에 가입 시 개인/간이 과세자로 선택하시어 가입해주시면 됩니다.',
          id: 2,
        },
        {
          title: '가입 시 필요한 서류가 있나요?',
          content:
            '사업자 여부에 따라 구분됩니다.<br/><br/>1) 개인/간이 과세자 : 없음<br/>2) 사업자 : 사업자등록증, 통신판매업 신고증',
          id: 3,
        },
        {
          title: '가입 시 사업자 유형을 선택하는 기준이 뭔가요?',
          content:
            "세금계산서 발행 유무를 기준으로 두가지 유형으로 구분됩니다.<br/><br/>1) 개인/간이 과세자 (세금계산서 미발급): 사업소득으로서 수익에서 **소득세 3.3%(원천징수)**를 제하고 별도의 세금계산서 발행 과정 없이 등록된 계좌로 입금해드립니다.<br/><br/>2) 사업자 (세금계산서 발급): **환급 신청한 금액(부가세 포함)**에 대한 세금계산서를 몰파이 운영사인 (주)지니웍스로 발행해주셔야 합니다.<br/><br/>세금계산서 발행에 필요한 이메일 및 (주)지니웍스의 사업자 등록증은 파트너 어드민 <a href='https://my.mallpie.kr/customer/notice/41'>📢공지사항</a>에 안내되어 있습니다.",
          id: 4,
        },
        {
          title: '탈퇴는 어떻게 하나요?',
          content:
            '[파트너 어드민 우측 상단 프로필 메뉴] > [내 정보 관리] > [회원 탈퇴] 에서 원하는 경우 언제든 탈퇴를 신청하실 수 있습니다.<br/><br/>파트너 탈퇴 시 정산되지 않은 금액이 남아있거나, 거래중인 주문이 있는 경우 정산 및 거래가 종료(구매확정)된 후 탈퇴가 가능합니다.<br/><br/>탈퇴 후에는 동일한 이메일로의 재가입은 7일 후에 하실 수 있습니다.',
          id: 5,
        },
        {
          title: '탈퇴 후에도 정보를 볼 수 있나요?',
          content:
            '정산이 완료되지 않은 수익이 있는 경우 정산절차가 완료되기 전까지 조회가 가능합니다. 정산이 완료된 후에는 탈퇴처리가 완료되어 기존 계정의 정보 확인이 불가능합니다.',
          id: 6,
        },
        {
          title: '탈퇴 후에도 정산을 받을 수 있나요?',
          content:
            '개설하신 마이몰을 통해 발생한 수익은, 탈퇴 전까지의 거래건에 대해서만 지급됩니다. 탈퇴 후에 발생한 거래에 대한 수익금은 지급되지 않습니다.',
          id: 7,
        },
        {
          title: '비밀번호가 기억이 안나요.',
          content:
            '[파트너 어드민 로그인 페이지]에서 비밀번호 재설정 기능을 이용해주세요.',
          id: 8,
        },
      ],
      guide: [
        {
          title: '최초 설정한 마이몰 주소(url) 변경 할 수 있나요?',
          content:
            '최초 설정 후 변경이 불가하오니, 신중하게 정해주세요.<br/>영어, 숫자로 설정 가능하며 최종 주소는  *******.mallpie.kr 형태로 구성됩니다.',
          id: 1,
        },
        {
          title: '배너를 노출하려고 하는데 선택 가능한 배너가 없어요.',
          content:
            "홈화면 또는 기획전 페이지에 배너를 게시하려면, [전시/메뉴 관리 메뉴] > [배너관리] 에서 먼저 배너를 추가해주셔야 합니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/mall/manage-banner'>📘배너 관리 가이드</a>를 참고해 주세요.",
          id: 2,
        },
        {
          title: 'MD 추천 상품은 어떤 상품인가요?',
          content:
            '카테고리별 전문 MD가 시즌 & 타 마이몰 판매 상위 & 가격 경쟁력 등을 종합적으로 고려하여 추천하는 상품입니다.<br/><br/>상품 추가를 고려 중이라면 [상품 관리 메뉴] > [운영 상품 등록] > [MD 추천 상품] 큐레이션 관에서 매출 상승 가능성이 높은 상품들을 한눈에 확인하세요!',
          id: 3,
        },
        {
          title: '기획전은 어떻게 만드나요?',
          content:
            "[페이지/진열 관리 메뉴] > [페이지 관리] > [페이지 추가] 에서 상품 특성, 가격, 브랜드 등의 다양한 기준으로 상품을 큐레이션 하여 별도의 기획전 페이지를 따로 만들 수 있습니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/mallpnp/manage-page'>📘전시/기획전 만들기 가이드</a>를 참고해 주세요.",
          id: 4,
        },
        {
          title: '상품이 운영 중지 상태인데 무슨 의미인가요?',
          content:
            '상품 정보 오류, 상품 품절 등 여러 이유로 인하여 몰파이 관리자에 의해 게시가 중지되어 노출되지 않는 상품입니다.',
          id: 5,
        },
        {
          title: '주문이 발생했어요! 배송은 어떻게 진행되나요?',
          content:
            '판매 상품의 업체쪽에서 확인 후 배송 처리를 진행합니다. 주문건 별 배송 현황은 [주문 관리 메뉴]에서 확인 가능합니다.',
          id: 6,
        },
        {
          title: '어떤 상품으로 판매를 시작해야 할지 모르겠어요.',
          content:
            '[상품 관리] > [+운영 상품 등록]에서 AI 추천을 받아 보시면 어떨까요?<br/><br/>타깃 고객층의 연령과, 성별 그리고 관심 분야를 선택해 주시면<br/>판매량, 시즌 등을 반영하여 실시간으로 분석한 상품들을 추천해 드립니다. ',
          id: 7,
        },
        {
          title: '기획전을 생성해서 노출하고 싶어요.',
          content:
            "기획전  페이지 생성- 홈 화면에 노출 순으로 진행이 필요합니다. <br/><br/>1.  [페이지/진열 관리] > [페이지 관리]에서 진행하시고자 하는 기획전 페이지 추가 <br/>2. [페이지/진열 관리] > [홈 화면 관리] > [메뉴 설정]에서 기획전 페이지를 메뉴로 추가<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/mall/manage-page'>📘전시/기획전 만들기</a> 가이드를 참고해 주세요.",
          id: 8,
        },
      ],
      calc: [
        {
          title: '수익금 정산은 어떤 기준으로 어떻게 진행되나요?',
          content:
            "마이몰에서 상품이 판매되면, 판매 금액의 일부가 수익으로 적립되며 현금으로 입금해 드립니다.<br/><br/>수익금 정산은 구매확정된 날짜를 기준으로 익월 예치금으로 적립해드립니다. 예치금으로 적립된 수익금은 10,000원 이상부터 계좌로 환급 신청이 가능합니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/manage-settlements'>📘정산 안내 가이드</a>를 참고해 주세요.",
          id: 1,
        },
        {
          title: '수익금 정산 내역은 어디서 확인할 수 있나요?',
          content: '[마이몰 정산 메뉴] > [월별 정산 내역]에서 확인 가능합니다.',
          id: 2,
        },
        {
          title: '예치금 잔액이 10,000원 이상인데 예치금 환급이 안됐어요.',
          content:
            "[마이몰 정산 메뉴] > [예치금 내역]에서 환급 신청을 먼저 해주셔야 합니다. 예치금 환급은 '개인' 또는 '사업자' 구분에 따라 수익에 대한 세금 처리 방법이 상이하니, 아래 내용을 확인해주세요.<br/><br/>1) 개인/간이 과세자 (세금계산서 미발급): 예치금 환급 신청한 금액에 대한 소득세 3.3%(원천징수)를 제하고 입금 처리 해드리며, 예치금 환급 신청 시 3.3%에 해당하는 금액이 제외되어 보여집니다. 예치금 환급 신청 후 영업일 3일 이내에 신청하신 정산 계좌로 입금해드립니다.<br/><br/>2) 사업자(세금계산서 발급): 환급 신청한 금액(부가세 포함)에 대한 세금계산서를 몰파이 운영사인 (주)지니웍스로 발행해주셔야 합니다. 세금계산서 발행에 필요한 이메일 및 (주)지니웍스의 사업자 등록증은 파트너 어드민의 <a href='https://my.mallpie.kr/customer/notice/41'>📢공지사항</a>에 안내되어 있습니다.",
          id: 3,
        },
      ],
      etc: [
        {
          title: '쿠폰 발급할 수 있나요?',
          content:
            "네, 마이몰 회원들에게 쿠폰을 발급할 수 있습니다. 쿠폰 혜택을 제공하여 상품 판매를 더욱 활성화해보세요!<br/><br/>관련된 자세한 내용은 <a href='https://help-partner.mallpie.kr/mall/manage-coupon'>📘쿠폰 발급하기 가이드</a>를 참고해 주세요.",
          id: 1,
        },
      ],
    },
    seller: {
      inner: [
        {
          title: '입점 시 필요한 서류는 무엇인가요?',
          content:
            '1) 어드민 회원가입 시 : 사업자등록증 사본, 통신판매업 신고증 사본, 법인 명의 통장 사본<br/>2) 전자 계약 시 (signOK 플랫폼 이용) : 범용 공인인증서 또는 간편인증서<br/><br/>가 필요합니다.',
          id: 1,
        },
        {
          title: '지류 계약으로 진행 가능한가요?',
          content:
            '불가합니다. signOK 플랫폼 이용한 전자 계약으로만 진행되고 있습니다.',
          id: 2,
        },
        {
          title: '통신판매업 신고증 필수인가요?',
          content:
            '네, 전자상거래 소비자보호 관련 법률에 따라 통신판매업 신고 및 증빙은 필수입니다.',
          id: 3,
        },
        {
          title: '개인사업자도 입점 가능한가요?',
          content: '네, 가능합니다. 단, 간이과세자는 입점이 불가합니다.',
          id: 4,
        },
        {
          title: '입점 결과는 언제 알 수 있나요?',
          content:
            '입점 신청부터 승인까지는 최대 2주 소요될 수 있습니다.<br/> 입점 신청 확인 후 개별 연락드리고 있으니 참고 부탁드립니다.',
          id: 5,
        },
        {
          title: '쇼핑몰 연동 솔루션을 이용해서 등록할 수 있나요?',
          content:
            '네, 몰파이는 현재 사방넷과 연동이 되어있습니다. <br/> 사방넷 서비스를 이용 중이시면, 사방넷을 통해 상품 등록 및 주문처리가 가능합니다.',
          id: 6,
        },
        {
          title: '퇴점 신청은 어떻게 하나요?',
          content:
            '퇴점 신청은 고객센터 또는 담당 MD를 통해 접수받고 있습니다. 절차 진행 전에 거래 진행 중 내역, 미정산 건 등의 잔여 여부 확인이 필요하오니 먼저 <a href="https://my.mallpie.kr/customer/inquiry">고객센터 1:1 문의</a>로 퇴점 의사 남겨주시면 확인 후 세부 절차 안내드리겠습니다.',
          id: 7,
        },
        {
          title: '퇴점 시, 정산은 어떻게 진행되나요?',
          content:
            '퇴점 절차 진행 전에 입점사의 거래건에 대해서 미정산된 금액이 있는지 확인이 진행됩니다. 세금계산서 미발급 건이나, 아직 지급 처리가 이루어지지 않은 건이 있다면 이에 대해 처리 완료 후 퇴점이 가능합니다.',
          id: 8,
        },
        {
          title: '퇴점 후 재입점이 가능한가요?',
          content:
            '네, 재입점이 가능합니다. <br/><br/>다만, 재입점 시 기존에 사용하셨던 계정은 거래 내역 등은 이관이 되지 않습니다. 따라서 신규로 입점 신청을 해주셔야 합니다.',
          id: 9,
        },
      ],
      product: [
        {
          title: '수수료가 어떻게 되나요?',
          content:
            '기본 수수료는 15%이며, 건강기능식품은 20%입니다.<br/>카테고리에 따라 MD 협의를 통해 조정될 수 있습니다.',
          id: 1,
        },
        {
          title: '무조건 무료 배송으로 등록해야 하나요?',
          content: '네, 몰파이는 전 상품 무료배송입니다.',
          id: 2,
        },
        {
          title: 'N포털사이트에 상품 노출이 되나요?',
          content:
            '몰파이는 폐쇄몰로, N포털사이트에 가격 및 상품이 노출되지 않습니다.',
          id: 3,
        },
        {
          title: '상품 등록은 어떻게 하나요?',
          content:
            "최종 입점 승인 직후부터 입점사 어드민에서 상품 등록이 가능합니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-seller.mallpie.kr/product/create-product'>📘상품 등록 가이드</a>를 참고해 주세요.",
          id: 4,
        },
        {
          title: '상품 등록할 때 판매 상태는 어떻게 설정하면 되나요?',
          content:
            '판매중으로 설정하면, 검수가 끝나면 바로 판매됩니다.<br/>판매중지로 설정하면, 검수가 끝난 후 직접 판매중으로 다시 변경해주셔야 합니다.',
          id: 5,
        },
        {
          title: '상품 등록할 때 옵션 추가할 수 있나요?',
          content:
            "네, 가능합니다. [옵션 정보] > [상품 옵션] 에서 '옵션 설정' 선택 후 등록해주시면 됩니다.<br/><br/>옵션은 최대 3단계로 설정 가능하며, 옵션명에 해당 하는 각 옵션값을 콤마(,)로 구분하여 입력해주세요.<br/>옵션명, 옵션값을 모두 입력 후 '적용' 버튼을 누르시면 각 옵션값을 조합하여 옵션상품 목록이 생성됩니다.<br/><br/>관련된 자세한 내용은 <a href='https://help-seller.mallpie.kr/product/create-product'>📘상품 등록 가이드</a>를 참고해 주세요.",
          id: 6,
        },
        {
          title: '대표이미지, 프로모션 이미지 동영상/GIF를 사용해도 되나요?',
          content:
            '불가합니다. 대표이미지는 1MB 이하의 JP(E)G 파일로 자동 변환되어 저장됩니다.<br/>단, 이미지 용량이 큰 경우, 업로드가 제한됩니다.',
          id: 7,
        },
        {
          title: '대표이미지, 프로모션 이미지는 몇 개까지 등록 가능한가요?',
          content:
            '대표이미지는 최대 10개, 프로모션 이미지는 최대 4개까지 등록 가능합니다. (1개는 필수)<br/><br/>다양한 이미지를 등록하시면 상품의 정보를 충분히 전달할 수 있으니, 효과적으로 사용해보세요!',
          id: 8,
        },
        {
          title: '정보고시 내 모든 내용을 필수적으로 작성해야 하나요?',
          content:
            "상품등록 시 기재하셔야 하는 정보고시 항목은 전자상거래법에 의거하여 필수로 작성해 주셔야 합니다.<br/>자세한 내용은 <a href='https://www.law.go.kr/%ED%96%89%EC%A0%95%EA%B7%9C%EC%B9%99/%EC%A0%84%EC%9E%90%EC%83%81%EA%B1%B0%EB%9E%98%EB%93%B1%EC%97%90%EC%84%9C%EC%9D%98%EC%83%81%ED%92%88%EB%93%B1%EC%9D%98%EC%A0%95%EB%B3%B4%EC%A0%9C%EA%B3%B5%EC%97%90%EA%B4%80%ED%95%9C%EA%B3%A0%EC%8B%9C'>전자상거래 등에서의 상품 등의 정보제공에 관한 고시</a>를 참고해 주세요.<br/><br/>만약, 고시 내용이 상품정보에 포함되어 있는 경우, '별도표기'를 ON으로 설정하시면, 개별 고시 항목을 입력하지 않으셔도 됩니다.",
          id: 9,
        },
        {
          title:
            '품목이 명확하지 않은 경우, 정보고시를 어떻게 작성해야 하나요?',
          content:
            "소비자의 일반적인 인식과 기재 항목을 고려하여 카테고리를 결정하되, 유사한 품목이 없는 경우 '기타 용역' 또는 '기타 재화' 선택하고 템플릿에 따라 정보를 기재해 주세요.<br/><br/>이미 상품 상세 정보에 사진 등으로 내용이 명시되어 있다면, 템플릿에 문자로 입력하기 어려운 내용은 '상세페이지 참조' 등과 같이 기재해 주시면 됩니다.",
          id: 10,
        },
        {
          title: '상품 등록 직후 바로 판매가 되는 건가요?',
          content:
            '아닙니다. 모든 신규 상품은 등록되면 판매 시작 전에 담당 MD가 사전 검수를 진행합니다. MD 상품 승인이 완료 되어야 마이몰에 진열 및 판매될 수 있는 상태가 됩니다.<br/><br/>검수 진행 상황은 [상품 목록]의 승인 상태 항목에서 확인이 가능합니다.',
          id: 11,
        },
        {
          title: '상품 승인은 언제 되나요?',
          content:
            '평균적으로 영업일 기준 2일 내 처리 완료되고 있으며, 등록 건수가 많을 경우 승인이 지연될 수 있는 점 양해 부탁드립니다.',
          id: 12,
        },
        {
          title: '상품 대량 등록 되나요?',
          content:
            '네, [상품관리] > [대량등록] 메뉴에서 대량등록 엑셀 양식을 다운로드 해주세요. 양식에 맞게 정보 입력 후 업로드 하시면 상품을 대량등록할 수 있습니다.<br/><br/>엑셀 파일의 서식을 변경하거나, 입력값을 누락하면 대량 등록이 안 될 수 있으니, 정확히 입력해주세요.<br/>상품 업로드 후 반드시 정상적으로 등록되었는지 상품목록에서 확인하여 주시기 바랍니다.',
          id: 13,
        },
        {
          title: '판매 중인 상품을 수정 했습니다. 시스템에 언제 반영 되나요?',
          content:
            '재검수 항목이 수정된 경우, 담당 MD 승인이 되어야 수정된 정보로 시스템에 반영 됩니다.<br/>판매중인 상품의 정보를 변경하여 승인요청을 하셔도 상품은 기존 정보로 계속 판매상태로 유지됩니다. 긴급하게 반영이 필요한 경우 담당 MD쪽으로 별도 요청해주세요.<br/><br/>✔재검수 항목<br/>- 상품 정보 > 정상가, 판매가, 공급가<br/>- 옵션 정보 > 옵션 판매 가격, 옵션 공급가',
          id: 14,
        },
        {
          title: '판매 중인 상품을 비노출 하고 싶어요.',
          content:
            '상품을 판매중인 마이몰에서 비 노출 하시고자 하는 경우, 판매상태를 OFF로 변경해주세요.',
          id: 15,
        },
        {
          title:
            '상품 등록 및 수정 요청이 승인 거절 됐어요. 사유는 어디서 확인할 수 있나요?',
          content:
            "[상품 관리] > [상품 목록] 메뉴에서 승인상태 항목 '거절사유'를 클릭하시면 거절 사유를 확인 하실 수 있습니다.",
          id: 16,
        },
      ],
      order: [
        {
          title: '배송 템플릿 설정 가능한가요 ?',
          content:
            '네, [판매설정] > [배송템플릿] 메뉴에서 가능합니다. <br> 상품 등록 및 판매 전에 미리 배송 템플릿을 설정해 놓으시면 상품 등록 시 더욱 편리합니다.',
          id: 1,
        },
        {
          title: '주문 확인/발송 방법을 알고 싶어요',
          content:
            "전체 판매채널의 주문건은 통합적으로 확인 및 처리 가능합니다. <br> 자세한 내용은 <a href='https://help-seller.mallpie.kr/order/order-list'>📘주문 현황 조회 가이드</a>를 참고해 주세요.",
          id: 2,
        },
        {
          title: '주문이 접수되면 알람이 오나요?',
          content:
            '신규 주문 및 배송 지연, 상품 문의 등에 대한 알림을 카카오톡으로 보내드리고 있습니다. <br> 알림을 받으시면 해당 내용을 확인 하신 후 처리 부탁드립니다.',
          id: 3,
        },
        {
          title: '주문 발송 처리 순서가 어떻게 되나요?',
          content:
            "신규 주문 발생 시 '배송준비중' 처리와 '배송준비중' 처리 후 발송이 완료되면 '발송처리'를 해주셔야합니다. <br> 주문 상태별 주문처리는 <a href='https://help-seller.mallpie.kr/order/order-confirm'>📘주문처리 가이드</a>를 참고해주세요.",
          id: 4,
        },
        {
          title: '송장을 대량으로 업로드 할 수 있나요?',
          content:
            "네, 주문건이 많은 경우 [주문 관리] > [주문 목록] 메뉴에서 '엑셀로 발송처리'를 클릭하고 양식을 다운로드 해주세요. <br> 양식에 맞게 정보 입력 후 업로드 하시면 한 번에 발송 처리 할 수 있습니다. <br> 엑셀 파일의 서식을 변경하시거나, 입력값을 누락하면 대량 등록이 안 될 수 있으니, 정확히 입력해주세요.",
          id: 5,
        },
      ],
      orderStatus: [
        {
          title: '취소 상태가 취소 요청 상태인데 아직 취소 안된 건가요?',
          content:
            "네, 주문관리 &취소 관리 메뉴에서 취소 요청사항을 확인 후 승인 및 거절할 수 있습니다.<br/><br/>취소 승인하면 PG 승인 취소 요청 후 취소 완료됩니다. 취소 거절 시, 취소 사유를 입력하면 구매자에게 해당 사유가 표시됩니다.<br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/order/manage-cancel'>📘취소 요청 처리 가이드</a>를 참고해 주세요.",
          id: 1,
        },
        {
          title: "'배송 준비중' 상태인데 주문 취소 가능 한가요?",
          content:
            "불가 합니다. 상품 재고 부족이나 기타 이유로 인하여 배송 처리가 어려운 경우, 주문 완료 상태에서만 주문 취소가능 합니다.<br/><br/>'배송준비중' 주문건의 주문취소가 필요하다면, '결제완료' 상태로 변경하신 후 취소 처리해 주세요.<br/><br/>단, 주문 취소를 하시기 전에, 구매한 고객에게 따로 안내해 주세요.<br/><br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/order/manage-cancel'>📘주문 처리 - 주문 취소 처리 가이드</a>를 참고해 주세요.",
          id: 2,
        },
        {
          title:
            '교환 요청이 발생했는데, 원 주문 상품 금액과 다른 옵션 상품으로 변경 가능한가요?',
          content:
            "불가 합니다. 동일가 옵션 상품으로만 변경이 가능합니다.<br/><br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/order/manage-exchange'>📘교환 요청 처리 가이드</a>를 참고해 주세요.",
          id: 3,
        },
        {
          title: '교환 요청 승인 했는데, 바로 상품 보내면 되나요?',
          content:
            "먼저 구매자로부터 수거 완료여부 확인 후 '교환 수거 완료' 버튼을 눌러 교환 수거 완료 상태로 전환해 주세요. 그 다음 교환을 희망하는 상품에 대한 발송 정보를 입력하여 발송 처리 해주세요.<br/><br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/order/manage-exchange'>📘교환 요청 처리 가이드</a>를 참고해 주세요.",
          id: 4,
        },
        {
          title: '반품 요청이 발생했는데, 반품 배송비는 누가 부담하나요?',
          content:
            "반품 사유에 따라서 반품배송비의 고객 부담 유무가 결정됩니다.<br/><br/>고객 귀책으로 인해 상품을 반품하는 경우는 고객이 반품/교환 배송비를 부담하게 됩니다.<br/><br/>고객이 웹사이트에서 반품 신청을 하게 되면, 고객 귀책인 경우 미리 배송비를 선결제하거나 기존 결제금액에서 차감하여 환불금액을 계산합니다.<br/>만약, 웹사이트에서 반품요청을 하지 않고, 입점사에서 따로 반품요청을 받으시는 경우 배송비 선결제가 불가능하니 반품 시 동봉 또는 입점사 은행계좌로 배송비 부담에 대한 처리 방법을 협의해서 고객에게 안내해주시기 바랍니다.<br/><br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/order/manage-return'>📘교환 요청 처리 가이드</a>를 참고해 주세요.",
          id: 5,
        },
      ],
      calc: [
        {
          title: '정산은 어떤 기준으로 어떻게 진행되나요?',
          content:
            "매월 1일부터 말 일까지의 구매 확정건 이며, 아래 프로세스로 진행 됩니다.<br/><br/>자세한 내용은 <a href='https://help-seller.mallpie.kr/settlement/settlement'>📘정산 안내 가이드</a>를 참고해 주세요.<br/><br/><strong>-정산 프로세스 :</strong><br/>월별 정산금액 확인 → 익월 10일까지 세금계산서 발급 (입점사) → 익월 25일경에 정산 계좌로 입금",
          id: 1,
        },
        {
          title: '정산 내역은 어디서 확인할 수 있나요?',
          content:
            '[정산관리] > [월별 정산내역]에서 월 단위로 확인하실 수 있습니다.',
          id: 2,
        },
        {
          title: '최초 입점시 입력한 계정 정보는 어떻게 수정하나요?',
          content:
            '[입점사 어드민 우측 상단 프로필 메뉴] > [내 정보 관리] 메뉴에서 이름/휴대폰 번호 변경 가능합니다.',
          id: 3,
        },
        {
          title: '입금 계좌를 변경 하고 싶어요.',
          content:
            '[입점사 어드민 우측 상단 프로필 메뉴] > [업체정보 관리] 메뉴에서 계좌 변경 후 저장해주세요.',
          id: 4,
        },
      ],
      etc: [
        {
          title: '상품 문의가 인입되면 알람이 오나요?',
          content:
            '네, 매일 09시, 12시, 17시마다 문의 인입 현황이 카카오 알림톡으로 발송됩니다.<br/>[게시판 관리] > [상품 문의] 메뉴에서 내용 확인 후 빠른 시일 내에 답변해 주세요.',
          id: 1,
        },
      ],
    },
  };

  const menuList = {
    mymall: [
      {
        key: 'service',
        value: '서비스',
        path: '/faq/mymall/service',
      },
      {
        key: 'join',
        value: '가입/탈퇴',
        path: '/faq/mymall/join',
      },
      {
        key: 'guide',
        value: '이용가이드',
        path: '/faq/mymall/guide',
      },
      {
        key: 'calc',
        value: '수익 정산',
        path: '/faq/mymall/calc',
      },
      {
        key: 'etc',
        value: '기타',
        path: '/faq/mymall/etc',
      },
    ],
    seller: [
      {
        key: 'inner',
        value: '입점/퇴점',
        path: '/faq/seller/inner',
      },
      {
        key: 'product',
        value: '상품',
        path: '/faq/seller/product',
      },
      {
        key: 'order',
        value: '주문 배송',
        path: '/faq/seller/order',
      },
      {
        key: 'orderStatus',
        value: '취소/교환/반품',
        path: '/faq/seller/orderStatus',
      },
      {
        key: 'calc',
        value: '정산/정보변경',
        path: '/faq/seller/calc',
      },
      {
        key: 'etc',
        value: '기타',
        path: '/faq/seller/etc',
      },
    ],
  };

  return { typeList, categoryList, menuList };
};

export default useFaq;
