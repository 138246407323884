/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

import titleImg from 'static/personal/title.png';
import titleSubImg from 'static/personal/title-sub.png';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';

const Title = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const TitleStyle = css`
    background: linear-gradient(180deg, #c2ccff 0%, #fff 100%);
    .container {
      max-width: 1200px;
      padding-top: 200px;
      padding-bottom: 180px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        color: #000;
        font-size: 80px;
        font-weight: 800;
        line-height: 100%;
      }
      .sub {
        margin-top: 20px;
        color: #191919;
        /* color: #000; */
        font-size: 40px;
        font-weight: 800;
        line-height: 56px; /* 140% */
      }
      > .desc {
        margin-top: 10px;
        color: #444;
        /* color: #000; */
        font-size: 20px;
        font-weight: 400;
        line-height: 30px; /* 150% */
        letter-spacing: -0.2px;
      }
      button {
        margin-top: 32px;
        width: 333px;
        padding: 8px 8px 8px 45px;
        display: flex;
        /* margin: 51px auto 0px auto; */
        font-size: 25px;
        border-radius: 999px;
        border: 0;
        background: #000;
        color: #fff;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .arrow {
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6079ff;
          border-radius: 50%;
          .arrow-img {
            position: relative;
            animation: moveArrow 0.6s ease-in-out infinite alternate;
          }
        }
        @keyframes moveArrow {
          0% {
            transform: translateX(-3px);
          }
          100% {
            transform: translateX(3px);
          }
        }
      }
    }
    .img {
      position: relative;
      margin-top: 64px;
      img {
        width: 100%;
      }
    }
    .descWrap {
      margin-top: 42px;
      display: flex;
      align-items: flex-start;
      .imgDescWrap {
        width: 169px;
        img {
          width: 100%;
        }
      }
      > .desc {
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .imgDesc {
          color: #444;
          font-size: 25px;
          font-weight: 600;
          line-height: 150%; /* 37.5px */
          letter-spacing: -0.25px;
          text-align: left;
        }
        .imgSubDesc {
          margin-top: 20px;
          color: #444;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px; /* 150% */
          span {
            margin-left: 4px;
            color: #444;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px; /* 150% */
          }
        }
        .mall {
          color: #999;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px; /* 150% */
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px 100px;
        .title {
          font-size: 48px;
          font-weight: 800;
          line-height: 56px; /* 116.667% */
        }
        .sub {
          font-size: 20px;
          line-height: 30px; /* 150% */
        }
        > .desc {
          font-size: 16px;
          line-height: 24px; /* 150% */
          letter-spacing: -0.16px;
        }
        button {
          margin-top: 20px;
          width: 195px;
          padding: 8px 10px 8px 20px;
          font-size: 16px;
          .arrow {
            width: 30px;
            height: 30px;
          }
          .img {
            margin-top: 48px;
          }
        }
        .descWrap {
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          .imgDescWrap {
            width: 39.313px;
            height: 29px;
            img {
              width: 100%;
            }
          }
          > .desc {
            margin-left: 0px;
            .imgDesc {
              margin-top: 12px;
              font-size: 14px;
              line-height: 22px; /* 157.143% */
              letter-spacing: -0.14px;
            }
            .imgSubDesc {
              margin-top: 12px;
              font-size: 14px;
              line-height: 20px; /* 142.857% */
              span {
                font-size: 14px;
                line-height: 20px; /* 142.857% */
              }
            }
            .mall {
              font-size: 14px;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }
    }
  `;

  return (
    <div css={TitleStyle}>
      <div className="container">
        <h2 className="title">개인</h2>
        <h3 className="sub">
          필요한 상품 구매하고{isMobile && <br />} 리워드까지 받는 돈 버는 쇼핑
        </h3>
        <p className="desc">
          생필품부터 가전, 가구까지 필요한 상품을{isMobile && <br />} 쇼핑몰에
          담아 {isPc && <br />}
          최저가로 구매하고,{isMobile && <br />} 리워드까지 받는 똑똑한 쇼핑을
          시작하세요.
        </p>
        <a
          href="https://my.mallpie.kr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            5분만에 몰 만들기
            <div className="arrow">
              <ArrowRight className="arrow-img" />
            </div>
          </button>
        </a>
        <div className="img">
          <img src={titleImg} alt="개인 | 이미지" title="개인" />
        </div>
        <div className="descWrap">
          <div className="imgDescWrap">
            <img src={titleSubImg} alt="subimage" />
          </div>
          <div className="desc">
            <h3 className="imgDesc">
              자취하면서 매일 쓰고 먹는 생활용품, 닭가슴살 등<br />
              원하는 상품을 담아 저도 구매하고, 친구들에게도 공유해요!
              <br />
              최저가로 상품도 구매하고, 소소한 용돈도 벌어요.
            </h3>
            <p className="imgSubDesc">
              대학생<span>박세민</span>
            </p>
            <p className="mall">seminmart.mallpie.kr</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
