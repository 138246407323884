/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

import down from 'static/revenue/down.png';
import up from 'static/revenue/up.png';
import title from 'static/revenue/title.png';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';

const Title = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const TitleStyle = css`
    background: linear-gradient(180deg, #f7c9c9 0%, #fff 100%);
    .container {
      max-width: 1200px;
      padding-top: 200px;
      padding-bottom: 120px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        color: #000;
        font-size: 80px;
        font-weight: 800;
        line-height: 130%;
        span {
          display: inline-flex;
          align-items: center;
          img {
            width: 75px;
            margin: 0 15px;
          }
        }
      }
      .sub {
        margin-top: 20px;
        color: #191919;
        font-size: 40px;
        font-weight: 800;
        line-height: 56px; /* 140% */
      }
      > .desc {
        margin-top: 42px;
        color: #444;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px; /* 150% */
        letter-spacing: -0.2px;
      }
      button {
        margin-top: 42px;
        width: 333px;
        padding: 8px 8px 8px 45px;
        display: flex;
        font-size: 25px;
        border-radius: 999px;
        border: 0;
        background: #000;
        color: #fff;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .arrow {
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6079ff;
          border-radius: 50%;
          .arrow-img {
            position: relative;
            animation: moveArrow 0.6s ease-in-out infinite alternate;
          }
        }
        @keyframes moveArrow {
          0% {
            transform: translateX(-3px);
          }
          100% {
            transform: translateX(3px);
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px 100px;
        .title {
          font-size: 36px;
          font-weight: 800;
          line-height: 120%; /* 116.667% */
          span {
            display: flex;
            justify-content: center;
            img {
              width: 36px;
              margin: 0 6px;
            }
          }
        }

        > .desc {
          font-size: 16px;
          line-height: 24px; /* 150% */
          margin-top: 20px;
          letter-spacing: -0.16px;
        }
        button {
          margin-top: 20px;
          width: 195px;
          padding: 8px 10px 8px 20px;
          font-size: 16px;
          .arrow {
            width: 30px;
            height: 30px;
            .arrow-img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  `;
  return (
    <div css={TitleStyle}>
      <div className="container">
        <h2 className="title">
          몰파이만이 가능한
          <br />
          <span>
            비용은 <img src={down} alt="down" title="down" /> 제로,
          </span>
          <span>
            수익은
            <img src={up} alt="up" title="up" /> 더 크게
          </span>
        </h2>
        <p className="desc">
          구매 전환율 고민을 덜어 줄 매력적인{isMobile && <br />} 쇼핑몰
          디자인과 {isPc && <br />}전 카테고리를 커버하는 상품을
          {isMobile && <br />} 무료로 연동하고 수익 창출을 시작하세요!
        </p>
        <a
          href="https://my.mallpie.kr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            5분만에 몰 만들기
            <div className="arrow">
              <ArrowRight className="arrow-img" />
            </div>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Title;
