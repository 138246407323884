/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import target1Img from 'static/home/target1.png';
import target2Img from 'static/home/target2.png';
import target3Img from 'static/home/target3.png';
import target4Img from 'static/home/target4.png';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Answer } from 'static/home/answer.svg';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsMobile } from 'core/hooks/useResponsive';

const Target = () => {
  const { mq } = useTheme();

  const ref = useRef(null);
  const navigator = useNavigate();
  const [active, setActive] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [start, setStart] = useState(true);
  const isMobile = useIsMobile();
  const swiperRef = useRef(null);

  const targetColor = ['#6079FF', '#FF5B00', '#9E3DDA', '#7A7A7A'];
  const bgColor = ['#b9c4ff', '#ffe3c2', '#d8c9f7', '#bac3c9'];

  const TargetStyle = css`
    width: 100%;
    height: 5000px;
    .wrap {
      > .cont {
        display: flex;
        padding-top: 100px;
        flex-direction: column;
        width: 100%;
        height: 1000px;
        transition: all 0.5s;
        background: linear-gradient(
          180deg,
          #fff 0%,
          ${bgColor[active]} 50%,
          #fff 100%
        );
        .top {
          display: flex;
          justify-content: space-between;
          align-items: end;
          width: 1276px;
          margin: 0 auto;
          .title {
            font-size: 56px;
            font-weight: 800;
            line-height: 76px;
            span {
              color: ${targetColor[active]};
              transition: all 0.5s;
            }
          }
          .menu {
            display: flex;
            gap: 64px;
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            li {
              cursor: pointer;
            }
            li.active {
              color: #000;
              font-weight: 700;
              border-bottom: 3px solid #000;
            }
          }
        }
        .list {
          margin-top: 48px;
          width: 100%;
          height: 540px;
          li {
            width: 1276px;
            padding: 48px;
            background: #fff;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              .title {
                font-size: 48px;
                font-weight: bold;
                line-height: 56px;
              }
              .cont {
                margin-top: 10px;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                color: #989898;
              }
              .more {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                margin-top: 32px;
                display: flex;
                gap: 10px;
                cursor: pointer;
                color: #6079ff;
                align-items: center;
              }
            }
            .right {
              width: 670px;
              .text {
                padding-left: 140px;
                position: relative;
                background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 0) 0%,
                  #fff 40%
                );
                margin-top: -95px;
                padding-top: 65px;
                svg {
                  position: absolute;
                  top: 65px;
                  left: 30px;
                }
                .title {
                  font-size: 32px;
                  line-height: 42px;
                  font-weight: bold;
                }
                .sub {
                  margin-top: 24px;
                  line-height: 27px;
                  color: #444;
                  strong {
                    font-weight: bold;
                  }
                  span {
                    color: #999;
                  }
                }
              }
              img {
                width: 100%;
              }
            }
          }
          .swiper-slide {
            opacity: 0.5;
            transition: opacity 0.5s;
            &.swiper-slide-active {
              opacity: 1;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        > .cont {
          .top {
            width: 100%;
            padding: 0 20px;
            flex-direction: column;
            align-items: start;
            gap: 40px;
            .title {
              font-size: 34px;
              font-weight: 800;
              line-height: 46px;
            }
            .menu {
              gap: 20px;
              font-size: 18px;
              line-height: 27px;
            }
          }
          .list {
            margin-top: 30px;
            height: auto;
            li {
              width: calc(100% - 40px);
              flex-direction: column;
              justify-content: start;
              padding: 24px;
              .left {
                width: 100%;
                .title {
                  font-size: 28px;
                  line-height: 36px;
                }
                .cont {
                  font-size: 16px;
                  line-height: 24px;
                }
                .more {
                  margin-top: 20px;
                  font-size: 14px;
                  line-height: 20px;
                  gap: 4px;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .right {
                width: 100%;
                margin-top: 20px;
                .text {
                  padding: 26px 10px 0 10px;
                  margin-top: 0px;
                  .title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                  }
                  svg {
                    width: 39px;
                    height: 29px;
                    top: -20px;
                    left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end start'],
  });

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    setActive(latest < 0.21 ? 0 : latest < 0.41 ? 1 : latest < 0.61 ? 2 : 3);
    if (latest > 0) {
      setStart(false);
    } else {
      setStart(true);
    }
    if (latest > 0 && latest < 0.835) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  });

  const onClickTab = (num: number) => {
    if (ref?.current) {
      window.scrollTo({
        top: ref.current.offsetTop + 1175 * num,
      });
    }
  };

  useEffect(() => {
    if (swiperRef?.current) {
      swiperRef.current.swiper.slideTo(active);
    }
  }, [active]);

  return (
    <div css={TargetStyle} ref={ref}>
      <motion.div
        className="wrap"
        style={{
          position: fixed ? 'fixed' : 'relative',
          y: start ? '-40px' : fixed ? '-40px' : '400%',
          left: 0,
          top: 0,
          width: '100%',
        }}
      >
        <div className="cont">
          <div className="top">
            <h2 className="title">
              누구나, 어떤 상황에서든 <br />
              <span>몰파이로 쉬운 시작</span>
            </h2>
            <ul className="menu">
              <li
                className={active === 0 ? 'active' : ''}
                onClick={() => onClickTab(0)}
              >
                개인
              </li>
              <li
                className={active === 1 ? 'active' : ''}
                onClick={() => onClickTab(1)}
              >
                크리에이터
              </li>
              <li
                className={active === 2 ? 'active' : ''}
                onClick={() => onClickTab(2)}
              >
                모임·단체
              </li>
              <li
                className={active === 3 ? 'active' : ''}
                onClick={() => onClickTab(3)}
              >
                기업
              </li>
            </ul>
          </div>
          <Swiper
            className="list"
            slidesPerView="auto"
            tag="ul"
            centeredSlides
            spaceBetween={48}
            ref={swiperRef}
            onSlideChange={swiper => {
              if (ref?.current) {
                window.scrollTo({
                  behavior: 'smooth',
                });
              }
            }}
          >
            <SwiperSlide
              tag="li"
              onClick={() => isMobile && navigator('/intro/personal')}
            >
              <div className="left">
                <h3 className="title">개인</h3>
                <p className="cont">
                  필요한 상품 최저가로 구매하고
                  <br />
                  리워드까지 받는 돈 버는 쇼핑
                </p>
                <Link to="/intro/personal" className="more">
                  자세히보기 <MainArrow />
                </Link>
              </div>
              <div className="right">
                <img src={target1Img} alt="개인 | 이미지" title="개인" />
                <div className="text">
                  <Answer />
                  <h3 className="title">
                    생필품은 최저가로 구매하고
                    <br />
                    용돈까지 버는 내 쇼핑몰이 생겼어요
                  </h3>
                  <p className="sub">
                    대학생 <strong>박세민</strong>
                    <br />
                    <span>seminmart.mallpie.kr</span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              tag="li"
              onClick={() => isMobile && navigator('/intro/creator')}
            >
              <div className="left">
                <h3 className="title">크리에이터</h3>
                <p className="cont">
                  팔로워들에게 상품을 매력적으로
                  <br />
                  추천하고, 판매하는 가장 쉬운 방법
                </p>
                <Link to="/intro/creator" className="more">
                  자세히보기 <MainArrow />
                </Link>
              </div>
              <div className="right">
                <img
                  src={target2Img}
                  alt="크리에이터 | 이미지"
                  title="크리에이터"
                />
                <div className="text">
                  <Answer />
                  <h3 className="title">
                    저와 같은 마이크로 크리에이터도
                    <br />내 브랜드 쇼핑몰을 만들 수 있어요
                  </h3>
                  <p className="sub">
                    리빙 크리에이터 <strong>김제이</strong>
                    <br />
                    <span>jeihome.mallpie.kr</span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              tag="li"
              onClick={() => isMobile && navigator('/intro/class')}
            >
              <div className="left">
                <h3 className="title">모임·단체</h3>
                <p className="cont">
                  우리 모임 쇼핑몰에서 알뜰하게 구매하고,
                  <br />
                  모임비까지 버는 우리만의 쇼핑몰
                </p>
                <Link to="/intro/class" className="more">
                  자세히보기 <MainArrow />
                </Link>
              </div>
              <div className="right">
                <img
                  src={target3Img}
                  alt="모임 단체 | 이미지"
                  title="모임 단체"
                />
                <div className="text">
                  <Answer />
                  <h3 className="title">
                    우리 동아리에 필요한 물품 구매하고
                    <br />
                    쌓인 리워드로 모임비까지 챙겨요
                  </h3>
                  <p className="sub">
                    풋볼 커뮤니티 <strong>FC아자자</strong>
                    <br />
                    <span>azaza.mallpie.kr</span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              tag="li"
              onClick={() => isMobile && navigator('/intro/company')}
            >
              <div className="left">
                <h3 className="title">기업</h3>
                <p className="cont">
                  우리 고객에게 쇼핑 서비스를 제공하고
                  <br />
                  판매 수익을 만드는 올인원 시스템
                </p>
                <Link to="/intro/company" className="more">
                  자세히보기 <MainArrow />
                </Link>
              </div>
              <div className="right">
                <img src={target4Img} alt="기업 | 이미지" title="기업" />
                <div className="text">
                  <Answer />
                  <h3 className="title">
                    몰파이를 도입하고 고객 리텐션과 <br />
                    체류 시간 고민이 해결되었어요
                  </h3>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </motion.div>
    </div>
  );
};

export default Target;
