/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import list1Img from 'static/company/section3_list1.png';
import list2Img from 'static/company/section3_list2.png';
import list3Img from 'static/company/section3_list3.png';
import list4Img from 'static/company/section3_list4.png';
import React from 'react';

const Section3 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section3Style = css`
    padding-bottom: 200px;
    .container {
      width: 1352px;
      margin: 0 auto;
      .title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 76px;
        span {
          color: #6079ff;
        }
      }
      ul {
        display: flex;
        gap: 24px;
        margin-top: 48px;
        li {
          width: 320px;
          img {
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      padding-bottom: 96px;
      .container {
        width: 100%;
        padding: 0px 0px 48px 0px;
        .title {
          padding-left: 20px;
          font-size: 32px;
          line-height: 42px;
          span {
            color: #6079ff;
          }
        }
        .scroll {
          width: 100%;
          overflow-x: scroll;
          padding: 0 20px 10px 20px;
          ul {
            margin-top: 40px;
            width: 996px;
            gap: 12px;
          }
        }
      }
    }
  `;

  return (
    <div css={Section3Style}>
      <div className="container">
        <h2 className="title">
          <span>국내 대표 기업이 선택한</span>
          <br />
          몰파이 커머스
        </h2>
        <div className="scroll">
          <ul>
            <li>
              <img
                src={list1Img}
                alt="유플러스 콕 | 이미지"
                title="유플러스 콕"
              />
            </li>
            <li>
              <img src={list2Img} alt="페이코 | 이미지" title="페이코" />
            </li>
            <li>
              <img src={list3Img} alt="하나카드 | 이미지" title="하나카드" />
            </li>
            <li>
              <img src={list4Img} alt="HBAF | 이미지" title="HBAF" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section3;
