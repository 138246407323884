import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Section1 from 'components/terms/Section1';
import axios from 'core/config/axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Terms = () => {
  const { id } = useParams();
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      axios.post('/shop/terms', { termsCd: [0 + id] }).then(response => {
        setTerms(response.data.data[0]);
      });
    }
  }, [id]);
  console.log(terms);
  return (
    <Layout theme="white">
      {terms !== null && (
        <>
          <Meta title="약관/고지 | 몰파이" ogTitle="약관/고지 | 몰파이" />
          <Section1 data={terms} />
        </>
      )}
    </Layout>
  );
};

export default Terms;
