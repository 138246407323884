/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/store/store2_list1.png';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';
import { ReactComponent as ArrowLeft } from 'static/store/arrow_left.svg';
import { ReactComponent as Close } from 'static/user/mypage/close.svg';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element } from 'react-scroll';
import Modal from 'components/common/Modal';
import { useState } from 'react';

interface Section6Pros {
  onSubmit?: (data: any) => void;
  isSubmiting?: boolean;
}

const Section6 = ({ onSubmit, isSubmiting }: Section6Pros) => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setModal(true);
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const [modal2, setModal2] = useState<boolean>(false);

  const handleModal2Open = () => {
    setModal2(true);
  };

  const handleModal2Close = () => {
    setModal2(false);
  };

  const Store2Style = css`
    display: flex;
    justify-content: center;
    background: #282828;
    .wrap {
      width: 1352px;
      padding: 120px 0px;
      .box {
        display: flex;
        justify-content: space-between;
        .subTitle {
          color: #fff;
          font-size: 32px;
          font-weight: 400;
          line-height: 48px;
        }
        .title {
          font-size: 56px;
          font-style: normal;
          color: #fff;
          font-weight: 800;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
        .form {
          width: 693px;
          form {
            margin-top: 24px;
            border-radius: 36px;
            background: #fff;
            padding: 40px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            .formTitle {
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              margin-bottom: 32px;
              span {
                margin-top: 6px;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                opacity: 0.6;
              }
            }
            input:last-of-type {
              margin-bottom: 0;
            }
            input + .formTitle {
              padding-top: 32px;
              border-top: 1px solid #ebebeb;
            }
            label {
              display: block;
              margin-bottom: 8px;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              span {
                color: #fb3b3b;
              }
            }
            input,
            select,
            textarea {
              width: 100%;
              padding: 12px 16px;
              border-radius: 4px;
              border: 1px solid #e1e1e1;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
            input + label,
            .error + label,
            select + label,
            textarea + label,
            .divide + label {
              margin-top: 32px;
            }
            input + .error,
            .divide + .error {
              color: #fb3b3b;
              font-size: 14px;
              padding-top: 10px;
              display: block;
            }
            select {
              width: 100%;
            }
            .divide {
              display: flex;
              align-items: center;
              input {
                margin-bottom: 0;
              }
            }

            .terms {
              display: flex;
              justify-content: start;
              align-items: start;
              gap: 10px;
              padding-top: 20px;
              input {
                width: 20px;
                height: 20px;
              }
              input + label {
                margin-top: 0;
              }
              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 4px;
                strong {
                  color: #6079ff;
                }
              }
              strong {
                cursor: pointer;
                text-decoration: underline;
              }
              span {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #909090;
              }
            }
            button {
              width: 100%;
              border-radius: 27px;
              padding: 12px 32px;
              align-items: center;
              color: #fff;
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
              display: flex;
              align-items: center;
              margin-top: 32px;
              justify-content: center;
              cursor: pointer;
              background: #6079ff;
              border: 0;
              svg {
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      flex-direction: column;
      .wrap {
        width: 100%;
        padding: 96px 20px;

        .box {
          flex-direction: column;
          .subTitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
          .title {
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 42px;
          }
          .form {
            width: 100%;

            .subTitle {
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
              padding-bottom: 25px;
              border-bottom: 1px solid #ebebeb;
            }
            form {
              border-radius: 20px;
              margin-top: 24px;
              padding: 20px;
              .formTitle {
                font-size: 18px;
                line-height: 27px;
                padding-top: 32px;
                span {
                  font-size: 14px;
                  line-height: 21px;
                }
              }
              label {
                margin-bottom: 11px;
                font-size: 14px;
                line-height: 21px;
              }
              input,
              select,
              textarea {
                font-size: 14px;
                line-height: 21px;
              }
              input + .error,
              .divide + .error {
                font-size: 12px;
              }
              .terms {
                p {
                  font-size: 14px;
                  line-height: 21px;
                }
                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
              button {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  `;

  const ModalStyle = css`
    .close-button {
      cursor: pointer;
    }
    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .item {
        .item-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        .item-content {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .sub-text {
        color: #818181;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  `;

  const Variants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Element name="companyInquiry" className="element">
      <div css={Store2Style}>
        <div className="wrap" id="storeForm">
          <motion.div className="box">
            <motion.div
              className="text"
              initial="offscreen"
              whileInView="onscreen"
              variants={Variants}
              viewport={{ once: false }}
              transition={{
                ease: 'easeInOut',
                duration: 0.5,
                y: { duration: 0.5 },
              }}
            >
              <h3 className="subTitle">몰파이 비즈니스 도입 문의</h3>
              <h2 className="title">
                커머스로 만드는
                <br />
                <span>새로운 기회의 시작</span>
              </h2>
            </motion.div>
            <motion.div
              className="form"
              initial="offscreen"
              whileInView="onscreen"
              variants={Variants}
              viewport={{ once: false }}
              transition={{
                ease: 'easeInOut',
                duration: 0.5,
                y: { duration: 0.5 },
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                  기업/브랜드명<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="회사명을 입력해주세요."
                  {...register('compNm', { required: true, maxLength: 10 })}
                />
                <label>
                  담당자 연락처<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="000-0000-0000"
                  {...register('mobile', {
                    required: true,
                    pattern: {
                      value: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
                      message: '연락처 형식이 맞지 않습니다.',
                    },
                  })}
                />
                {errors.mobile && (
                  <span role="alert" className="error">
                    연락처 형식이 맞지 않습니다.
                  </span>
                )}
                <label>
                  이메일 주소<span>*</span>
                </label>
                <div className="divide">
                  <input
                    type="text"
                    {...register('id', {
                      required: true,
                      pattern: {
                        value: /^[A-Za-z0-9]*$/,
                        message: '이메일 형식이 맞지 않습니다.',
                      },
                    })}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;@&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" {...register('id2', { required: true })} />
                </div>
                {errors.id && (
                  <span role="alert" className="error">
                    이메일 형식이 맞지 않습니다.
                  </span>
                )}
                <label>업종</label>
                <input
                  type="text"
                  placeholder="예: 제조, 서비스, 식품, 패션 등"
                  {...register('sectors')}
                />

                <label>문의 내용</label>
                <textarea
                  cols={50}
                  style={{ resize: 'none' }}
                  rows={10}
                  placeholder="문의내용을 입력해주세요."
                  {...register('content')}
                ></textarea>

                <div className="terms">
                  <input
                    type="checkBox"
                    value="Y"
                    {...register('term', { required: true })}
                    id="term"
                  />
                  <label htmlFor="term" className="term">
                    <p>
                      [필수]{' '}
                      <strong onClick={handleModalOpen}>
                        개인(신용)정보 수집 · 이용
                      </strong>
                      에 동의 합니다.
                    </p>
                    <span>*상담 외 다른 목적으로 사용하지 않습니다.</span>
                  </label>
                </div>
                <div className="terms">
                  <input
                    type="checkBox"
                    value="Y"
                    {...register('marketing')}
                    id="marketing"
                  />
                  <label htmlFor="marketing" className="term">
                    <p>
                      [선택]{' '}
                      <strong onClick={handleModal2Open}>
                        마케팅 정보 수신
                      </strong>
                      에 동의합니다.
                    </p>
                  </label>
                </div>

                <button disabled={isSubmiting}>
                  몰파이 비즈니스 문의하기
                  <ArrowLeft />
                </button>
              </form>
            </motion.div>
          </motion.div>
        </div>
        <Modal
          open={modal2}
          enableButton={true}
          onClose={handleModal2Close}
          color="blue"
        >
          <div css={ModalStyle}>
            <div className="title close-icon">
              마케팅 수신 동의
              <div className="close-button" onClick={handleModal2Close}>
                <Close />
              </div>
            </div>
            <div className="modal-content">
              <div className="item">
                <div className="item-title">목적</div>
                <div className="item-content">
                  몰파이 서비스에 대한 광고 및 프로모션을 위한 알림 등의 발송
                </div>
              </div>
              <div className="item">
                <div className="item-title">항목</div>
                <div className="item-content">
                  기업명, 연락처, 이메일주소, 업종
                </div>
              </div>
              <div className="item">
                <div className="item-title">보유/이용기간</div>
                <div className="item-content">동의 철회 시</div>
              </div>
              <div className="sub-text">
                ※ 마케팅 수신 동의는 선택 사항이며, 동의를 거부하여도 서비스
                문의에는 영향이 없습니다. 또한, 마케팅 수신 동의 철회 관리는
                고객센터로 문의해 주세요.
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={modal}
          enableButton={true}
          onClose={handleModalClose}
          color="blue"
        >
          <div css={ModalStyle}>
            <div className="title close-icon">
              개인정보 수집·이용 동의서
              <div className="close-button" onClick={handleModalClose}>
                <Close />
              </div>
            </div>
            <div className="modal-content">
              <div className="item">
                <div className="item-title">목적</div>
                <div className="item-content">
                  비즈니스 문의 응답, 비즈니스 제안서 발송
                </div>
              </div>
              <div className="item">
                <div className="item-title">항목</div>
                <div className="item-content">
                  기업명, 연락처, 이메일주소, 업종
                </div>
              </div>
              <div className="item">
                <div className="item-title">보유/이용기간</div>
                <div className="item-content">
                  상담 서비스가 종료되는 시점
                  <br />
                  *관계 법률에 의해 보존할 필요가 있는 경우 일정 기간 보존
                </div>
              </div>
              <div className="sub-text">
                ※ 위 개인정보 수집 · 이용에 대한 동의를 거부할 수 있으며 거부할
                경우 상담 서비스 이용이 제한됩니다.
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Element>
  );
};

export default Section6;
