/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import close from 'static/main/popup/close.png';
import closeMobile from 'static/main/popup/close_mobile.png';
import popup from 'static/main/popup/popup.png';
import popupMobile from 'static/main/popup/popup_mobile.png';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onDontShowAgain: () => void;
  goToLink?: () => void;
}

const HomePopup = ({
  open,
  onClose,
  onDontShowAgain,
  goToLink,
}: PopupProps) => {
  const { mq } = useTheme();

  const HomePopupStyle = css`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    button {
      border: 0;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
    .wrap {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      height: 450px;
      border-radius: 30px;
      box-shadow: 10px 12px 30px 10px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 1000;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .more {
      font-size: 13px;
      text-align: right;
      padding: 4px 0px;
      background-color: transparent;
      cursor: pointer;
      button {
        color: #fff;
        background: rgba(40, 40, 40, 0.6);
        border: none;
        border-radius: 20px;
        padding: 10px 15px;
      }
    }

    ${mq.mobile} {
      .wrap {
        width: 327px;
        height: 327px;
        .close {
          width: 30px;
          height: 30px;
        }
      }
      .more {
        button {
          font-size: 12px;
        }
      }
    }
  `;

  return (
    <>
      {open && (
        <div css={HomePopupStyle}>
          <div className="wrap" onClick={goToLink}>
            <button
              className="close"
              onClick={e => {
                onClose();
                e.stopPropagation();
              }}
            >
              <PC>
                <img alt="close" src={close} />
              </PC>
              <Mobile>
                <img alt="close" src={closeMobile} />
              </Mobile>
            </button>
            <PC>
              <img alt="popup" src={popup} />
            </PC>
            <Mobile>
              <img alt="popup" src={popupMobile} />
            </Mobile>
            <div className="more">
              <button onClick={onDontShowAgain}>오늘 그만 보기</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePopup;
