import React, { useEffect } from 'react';

interface MapsProps {
  height?: number;
}

const Maps = ({ height = 442 }: MapsProps) => {
  const { kakao } = window as any;
  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.51221239326203, 127.03121499231517),
      level: 2,
    };
    const map = new kakao.maps.Map(container, options);

    const marker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(37.51221239326203, 127.03121499231517),
    });

    marker.setMap(map);
  });
  return <div id="map" style={{ width: '100%', height: `${height}px` }} />;
};

export default Maps;
