import Layout from 'components/common/Layout';
import Section1 from 'components/inquiry/Section1';
import Section2 from 'components/inquiry/Section2';

const Inquiry = () => (
  <Layout theme="white">
    <Section1 />
    <Section2 />
  </Layout>
);

export default Inquiry;
