/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { motion } from 'framer-motion';

import titleImg from 'static/company/title.png';
import title2Img from 'static/company/title2.png';
import titlemImg from 'static/company/title_m.png';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-scroll';
import title2mImg from 'static/company/title2_m.png';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';

const Title = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const TitleStyle = css`
    background: linear-gradient(
      180deg,
      #bac3c9 0%,
      rgba(186, 195, 201, 0) 80.44%
    );
    .container {
      max-width: 1200px;
      padding-top: 200px;
      padding-bottom: 180px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        color: #000;
        font-size: 80px;
        font-weight: 800;
        line-height: 100%;
      }
      .sub {
        margin-top: 20px;
        color: #191919;
        font-size: 40px;
        font-weight: 800;
        line-height: 56px;
      }
      button {
        margin-top: 32px;
        width: 389px;
        padding: 8px 8px 8px 45px;
        display: flex;
        /* margin: 51px auto 0px auto; */
        font-size: 25px;
        border-radius: 999px;
        border: 0;
        background: #000;
        color: #fff;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .arrow {
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #6079ff;
          border-radius: 50%;
          .arrow-img {
            position: relative;
            animation: moveArrow 0.6s ease-in-out infinite alternate;
          }
        }
        @keyframes moveArrow {
          0% {
            transform: translateX(-3px);
          }
          100% {
            transform: translateX(3px);
          }
        }
      }
      .img {
        position: relative;
        margin-top: 64px;
        width: 1104px;
        img {
          width: 100%;
        }
        .sub {
          width: 1072px;
          position: absolute;
          left: 0px;
          top: 100px;
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px 100px;
        .title {
          font-size: 48px;
          font-weight: 800;
          line-height: 56px;
        }
        .sub {
          font-size: 20px;
          line-height: 30px;
        }
        button {
          margin-top: 20px;
          width: 234px;
          padding: 8px 10px 8px 20px;
          font-size: 16px;
          .arrow {
            width: 30px;
            height: 30px;
            .arrow-img {
              width: 20px;
              height: 20px;
            }
          }
        }
        .img {
          width: 253px;
          margin-top: 48px;
          .sub {
            width: 315px;
            left: -30px;
            top: 0px;
          }
        }
      }
    }
  `;

  const yVariants = {
    offscreen: {
      y: '100%',
      opacity: 0,
    },
    onscreen: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yDelayVariants = {
    offscreen: {
      scale: 0,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
  };

  return (
    <div css={TitleStyle}>
      <div className="container">
        <h2 className="title">기업</h2>
        <h3 className="sub">
          쇼핑 서비스를 제공하는
          <br />
          가장 쉬운 시작, 몰파이 비즈니스
        </h3>
        <Link to="companyInquiry" spy={true} smooth={true} duration={500}>
          <button>
            몰파이 비즈니스 도입 문의
            <div className="arrow">
              <ArrowRight className="arrow-img" />
            </div>
          </button>
        </Link>
        <motion.div
          className="img"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.7, once: true }}
        >
          <motion.img
            src={isMobile ? titlemImg : titleImg}
            alt="titleimage"
            variants={yVariants}
          />
          <motion.img
            className="sub"
            src={isMobile ? title2mImg : title2Img}
            alt="title2image"
            variants={yDelayVariants}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Title;
